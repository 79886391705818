.video {
  background: transparent;
  margin-bottom: 12px;
  & + & {
    //margin-top: 36px;
    //@media all and (max-width: 480px) {
    //  margin-top: 16px!important;
    //}
  }

  &Title {
    display: flex;
    align-items: center;
    margin: 12px 0 16px;

    //max-width: 200px;
    & span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    @media all and (max-width: 480px) {
      margin: 8px 0 0
    }
  }
}
