.title {
  margin-top: 44px;

  font-family: var(--playfair);
  color: var(--brown);
  line-height: 115%;
}

.text {
  margin: 18px 0 28px;

  line-height: 1.7;
  white-space: pre-line;
}
