.wrapper {
  display: flex;
  align-items: center;

  > span {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.accent {
    color: var(--accent);
  }
}

.icon {
  margin-right: 8px;
  flex-shrink: 0;
}
