.wrapper {
  position: relative;
}

.citySelector {
  position: absolute;
  top: -28px;
  transform: translateY(-100%);
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  :global(.vkuiButton__in.Button__in) {
    padding-left: 0;
    padding-right: 0;
  }
}
