.fileInput {
  position: relative;
  overflow: hidden;
  display: flex;

  width: 80px;
  height: 80px;

  color: red;

  > span:global(.vkuiButton__in) {
    justify-content: center;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: contain;

    border: 1px solid var(--yellow);
    border-radius: 10px;
  }
}
