.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;

  :global(.vkuiButton__in.Button__in) {
    padding-left: 0;
    padding-right: 0;
  }
}

.header {
  color: #fff;
}
