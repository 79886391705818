.cardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 18px 14px;
}

.date {
  margin-bottom: 8px;

  font-size: 12px;
  line-height: 100%;

  color: var(--grey)
}
