.content {
  > * + * {
    margin-top: 12px;
  }
}

.cover {
  position: relative;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: 0;

  //width: 100%;
  //height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    //z-index: -2;
  }

  //transition: transform .25s ease-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 51.56%, #09004F 100%);
    z-index: 1;
  }
}

.title {
  margin-bottom: 14px;
}

.link {
  margin-bottom: 20px;
}

.notificationButton {
  margin-bottom: 32px;
}
