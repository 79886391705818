.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px 14px;

  border-radius: 10px;
  overflow: hidden;

  cursor: pointer;

  &:hover .cover {
    transform: scale(1.05);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 51.56%, #000000 100%);
    z-index: -1;
  }
}
.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  z-index: -2;

  transition: transform .25s ease-out;
}

.title {
  max-width: calc(100% - 20px)
}

.important {
  position: absolute;
  top: 16px;
  right: 14px;

  color: var(--accent)
}
