.wrapper {
  position: relative;
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  margin: auto;
}

.frame {

}
